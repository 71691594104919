<template>
  <div class="mt-2">
    <template v-if="websites.length > 0">
      <addButton
        toRoute="websitesoverview/createeditwebsite/"
        :label="$t('Add module')"
      />

      <v-card>
        <v-card-title>
          {{ $t("Modules") }}
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="websites"
          hide-default-header
          hide-default-footer
        >
          <template v-slot:[`item.actions`]="{ item }">
            <router-link
              :to="{
                name: 'createEditWebsite',
                params: { id: item._id.$oid },
              }"
            >
              <v-btn class="mr-1" icon color="orange">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </router-link>
            <router-link
              :to="{ name: 'deleteWebsite', params: { id: item._id.$oid } }"
            >
              <v-btn class="mr-1" icon color="red">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </router-link>
          </template>
        </v-data-table>
      </v-card>
    </template>
    <template v-else-if="!$store.getters.error">
      <preloader></preloader>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  created() {
    this.getWebsites();
  },
  computed: {
    ...mapGetters({
      websites: "allWebsites",
    }),
    headers() {
      return [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "Name",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "right",
        },
      ];
    },
  },

  methods: {
    getWebsites() {
      this.$request.get("/websites", null, (res) => {
        if (res.success) {
          this.$store.dispatch("setAllWebsites", res.data);
        } else {
          this.$store.dispatch("setError", res);
        }
      });
    },
  },
};
</script>
